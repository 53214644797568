
import { Fragment } from "react";


const Loader: React.FC<{
  isLoading: boolean;
  black?: boolean;
  children?: React.ReactNode | string;
}> = ({
  isLoading,
  children,
  black
}) => {
  const color = black ? 'bg-black' : 'bg-white';
  return <>
    {
      isLoading ?
      <div className='absolute w-full h-full flex flex-wrap place-content-center justify-center'>
        <div className="animate-spin w-16 h-16 flex flex-wrap place-content-center justify-center">
          <div className={`rounded-full w-2 h-2 m-2 opacity-50 ${color}`} />
          <div className={`rounded-full w-2 h-2 m-2 opacity-50 ${color}`} />
        </div>
      </div>
      :
      <div className='w-full h-full'>{children}</div>
    }
  </>
}

export { Loader };